import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { proxy, useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { store } from '~/pages/heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { Pivots } from '../component/Pivots'
import { useAsyncFn } from 'react-use'
import { apirc } from '~/configs/apirc'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import { getIndicatorAngular } from '~/modules/strength-state/getIndicatorAngular'

const symbolFutures = [
  'TX-1',
  'TWN-1',
  'YM-1',
  'NQ-1',
  'ES-1',
  'RTY-1',
  'FDX-1',
  'NK-1',
  'HSI-1',
  'CN-1',
  'CL-1',
  'GC-1',
  'DX-1',
]

export const stanliStore = proxy({
  symbolList: 'tw50' as 'tw50' | 'futures',
})

export const SidePane = memo<ReactProps>(function SidePane(props) {
  const symbolTW50 = useSnapshot(staticStore.symbol.tw50).map(s => s)

  const state = useSnapshot(stanliStore).symbolList
  const symbolList = state === 'tw50' ? symbolTW50 : symbolFutures

  const charting = useSnapshot(store.charting)

  // const mainMarketValue = getIndicatorAngular(state.mainMarketValue) * 100
  // const retailMarketValue = getIndicatorAngular(state.retailMarketValue) * 100

  //大戶力道
  const [{ value: value1 }, fetchValues1] = useAsyncFn(async () => {
    const values = await apirc.marketDataAPI.dashboardData.fetch({
      target: 'dashboard_bs_indicator',
    })

    return {
      percentValue: getIndicatorAngular(values.value) * 100,
    }
  }, [])

  //大戶掛單
  const [{ value: value2 }, fetchValues2] = useAsyncFn(async () => {
    const values = await apirc.marketDataAPI.dashboardData.fetch({ target: 'dashboard_trade_info' })

    return {
      percentValue: getIndicatorAngular(values.value) * 100,
    }
  }, [])

  useIntervalNow(() => {
    fetchValues1()
    fetchValues2()
  }, 10000)

  return (
    <styledss.container>
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 136px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <CirculProgressbar value={value1?.percentValue ?? 0}>大戶力道</CirculProgressbar>
        <CirculProgressbar value={value2?.percentValue ?? 0}>大戶掛單</CirculProgressbar>
      </div>

      <div
        css={css`
          height: 156px;
          width: 100%;
          padding: 4px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <TitleSymbolQuote.Default symbol={charting.symbol} />
        <div
          css={css`
            width: 100%;
            height: calc(100% - 30px);
          `}
        >
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={14}
            ticksHeight={16}
            transparency={0.4}
            priceTicksSize={14}
            priceTicksMargin={-15}
          />
        </div>
      </div>
      <div
        css={css`
          height: 180px;
          width: 100%;
          padding: 4px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <Pivots />
      </div>
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 32px;
          gap: 4px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <OutlinedButton list='tw50'>股票商品</OutlinedButton>
        <OutlinedButton list='futures'>期貨商品</OutlinedButton>
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 516px);
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <SimpleQuoteListInteractWithChart
          data={symbolList}
          chart={store.charting}
        />
      </div>
    </styledss.container>
  )
})

const styledss = {
  container: styled.div`
    ${fill_vertical_cross_center};
    gap: 2px;
    padding: 2px;
  `,
}

//
const OutlinedButton = memo<ReactProps<{ list: typeof stanliStore.symbolList }>>(
  function OutlinedButton(props) {
    const state = useSnapshot(stanliStore).symbolList

    const styleds = {
      container: styled.div<{ select: boolean }>`
        ${fill_horizontal_all_center};
        width: 72%;
        height: 32px;
        background-color: #fafafa;
        border: 1px solid #c1c1c1;
        border-bottom: 4px solid ${props_ => (props_.select ? '#00affe' : '#ffffff')};
        cursor: pointer;
        border-radius: 4px 4px 0 0;
        &:hover {
          border-bottom: 4px solid ${props_ => (props_.select ? '#00affe' : '#f5f5f5')};
          background-color: #f5f5f5;
        }
      `,
    }

    return (
      <styleds.container
        select={state === props.list}
        onClick={() => {
          stanliStore.symbolList = props.list
        }}
      >
        {props.children}
      </styleds.container>
    )
  },
)

const CirculProgressbar = memo<ReactProps<{ value: number }>>(function CirculProgressbar(props) {
  const color = props.value > 0 ? '#ff3333' : props.value < 0 ? '#33bb33' : '#00000000'
  return (
    <CirculProgressbarStyled.Container>
      <CirculProgressbarStyled.ProgressbarContent>
        <CircularProgressbar
          value={Math.abs(props.value)}
          //text={`${props.value.toFixed(1)}%`}
          circleRatio={0.75}
          strokeWidth={12}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            strokeLinecap: 'butt',
            textColor: '#111111',
            textSize: 16,
            pathColor: color,
            trailColor: '#cccccc',
          })}
        />
        <CirculProgressbarStyled.valueArea
          css={css`
            color: ${props.value > 0 ? '#eb483f' : props.value < 0 ? '#33bb33' : '#666666'};
          `}
        >
          {props.value.toFixed(1)}%
        </CirculProgressbarStyled.valueArea>
      </CirculProgressbarStyled.ProgressbarContent>
      <CirculProgressbarStyled.NameTitle>{props.children}</CirculProgressbarStyled.NameTitle>
    </CirculProgressbarStyled.Container>
  )
})

export const CirculProgressbarStyled = {
  Container: styled.div`
    ${flex.v.allCenter}
    width: 80%;
    height: 136px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 8px;
  `,
  valueArea: styled.div`
    ${flex.v.allCenter}
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 0 2px 2px #cccccc;
    font-weight: 400;
  `,
  ProgressbarContent: styled.div`
    position: relative;
    ${flex.v.allCenter}
    width: 80%;
    height: 96px;
  `,
  NameTitle: styled.div`
    ${flex.v.allCenter}
    width: 60%;
    height: 24px;
    background-color: #434d6e33;
    border-radius: 5px;
    font-size: 14px;
  `,
}
